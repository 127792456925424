import React from 'react'
import styled from 'styled-components'
import { above, LargeP } from '../elements'

const BenefitsHighlight = () => (
  <Zone>
    <h2>Benefits</h2>
    <LightLargeP>
      We are proud to offer our team members an outstanding benefits package.
    </LightLargeP>
    <List>
      <Item>
        <h5>Health & Wellness</h5>
        <p>
          We cover a generous contribution to your health insurance premium and provide
          inexpensive plans for your family too.
        </p>
      </Item>
      <Item>
        <h5>Financial</h5>
        <p>
          In business for over 30 years we offer financial stability, competitive salaries, and top driver pay.
        </p>
      </Item>
      <Item>
        <h5>Learning & Education</h5>
        <p>
          We provide annual conference stipends and internal online learning
          classes. As a small team you can explore the different job
          opportunities throughout the organization.
        </p>
      </Item>
      <Item>
        <h5>Great Gear</h5>
        <p>
          D&D is always at the forefront of technology. We offer the latest in
          computer software and cutting edge transportation hardware.
        </p>
      </Item>
      <Item>
        <h5>Work Life Balance</h5>
        <p>
          Living in a small community has its benefits. Enjoy the commuter free
          drive with the exception of the occasional cattle heard and get home
          in time to watch the grass grow.
        </p>
      </Item>
      <Item>
        <h5>And More</h5>
        <p>
          We are a small company with big opportunity. We don’t have the red
          tape that the larger public companies have. Enjoy the larger benefits
          while working with spectacular co-workers.
        </p>
      </Item>
    </List>
  </Zone>
)

export default BenefitsHighlight

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  text-align: left;
`
const Item = styled.div`
  margin-top: 1rem;
  margin-right: 2%;
  flex: 1 0 100%;
  ${above.md`
    flex: 1 0 48%;
  `}
  ${above.lg`
    flex: 1 0 31%;
  `}
`
const LightLargeP = styled(LargeP)`
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto 2rem;
`
const Zone = styled.section`
  padding: 4rem 10%;
  position: relative;
  text-align: center;
  background: white;
`
