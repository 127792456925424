import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import BenefitsHighlight from '../components/BenefitsHighlight'

import {
  above,
  Zone,
  BoxWrapper,
  Box,
  BoxInner,
  CardTitle,
  Button,
  LinkWrapper,
  richGrey,
  DownloadButton,
  InlineBox,
} from '../elements'

import hero from '../images/slideshow/ddtsi-hagerman-id.png'

const CareersPage = ({ data }) => {
  const postNode = {
    title: `Careers - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Careers - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <Zone modifiers={['blank', 'center', 'short']} hero>
        <div className="zone-content">
          <h2>Committed to Your Success</h2>
          <h3>
            Our employees are the foundation of our brand and the reason for our
            consistent accomplishments. We value each and every one of our team
            members and applaud their contribution to our success.
          </h3>
        </div>
      </Zone>

      <Container>
        {/* <PageTitle>Careers</PageTitle> */}
        <ServiceWrapper>
          <ServiceBox>
            <BoxInner>
              <CardTitle>Company Drivers</CardTitle>
              <p>
                Drivers are the backbone of our company and Nation. We offer
                competitive pay packages, robust benefits as well as tools and
                education to advance your professional career and personal
                goals.
              </p>
              <DownloadButton
                rel="noopener noreferrer"
                href="https://intelliapp.driverapponline.com/c/ddtsi"
                target="_blank"
              >
                Driver Application&nbsp;
                <i className="material-icons">arrow_forward</i>
              </DownloadButton>
            </BoxInner>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/carriers-owner-operators" asModal>
              <BoxInner>
                <CardTitle>Carriers & Owner Operators</CardTitle>
                <p>
                  D&D carriers and owner operators always have peace of mind and
                  security when it comes to their transportation needs. Let us
                  do the paperwork while you collect the settlement checks.
                </p>
                <Button
                  modifiers={['outline']}
                  to="/carriers-owner-operators"
                  asModal
                >
                  View Openings&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </BoxInner>
            </FlexWrapper>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/office-careers" asModal>
              <BoxInner>
                <CardTitle>Office Careers</CardTitle>
                <p>
                  Transportation is a fast-paced and exhilarating industry where
                  variety, strategy and collaboration come together to solve
                  unique problems. Start an office career driving America
                  forward.
                </p>
                <Button modifiers={['outline']} to="/office-careers" asModal>
                  View Openings&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </BoxInner>
            </FlexWrapper>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/maintenance-technicians" asModal>
              <BoxInner>
                <CardTitle>Maintenance Technicians</CardTitle>
                <p>
                  We rely on our maintenance team to keep the machine
                  well-oiled. Join a team of master technicians dedicated to
                  keeping our fleet safe and on the road.
                </p>
                <Button
                  modifiers={['outline']}
                  to="/maintenance-technicians"
                  asModal
                >
                  View Openings&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </BoxInner>
            </FlexWrapper>
          </ServiceBox>
        </ServiceWrapper>
      </Container>
      <BenefitsHighlight />
      <Zone modifiers={['darkOverlay', 'center']} left hero image={hero}>
        <div className="zone-content">
          <Testimonial>
            <p>
              Trucks, and the companies that run them, are all mostly the same.
              What sets D&D above the rest is the people. They care about you
              and they care about their customers.
            </p>
            <cite>Raymond Brooks, D&D Driver for 22 years</cite>
          </Testimonial>
        </div>
      </Zone>
    </Layout>
  )
}

export default CareersPage

const ServiceBox = styled(Box)`
  flex: 0 0 100%;
  margin: 0 0 2rem 0;
  ${above.sm`
    flex: 0 0 49%;
  `};
`
const CardImg = styled.div`
  /* flex-flow: row wrap; */
  min-width: 208px;
  background: ${richGrey};
`
const HangingBoxInner = styled(BoxInner)`
  margin: 0;
  height: 100%;
  ${above.lg`
    margin: 1rem 0 1rem -2rem;
    height: auto;
  `}
`
const FlexWrapper = styled(LinkWrapper)`
  flex-flow: column;
  ${above.lg`
    flex-flow: row;
  `}
`
const ServiceWrapper = styled(BoxWrapper)`
  margin-top: -6rem;
`
const Testimonial = styled.blockquote`
  p {
    font-size: 1.35rem;
    line-height: 1.85rem;
  }
  cite,
  cite a {
    font-size: 1.15rem;
  }
`
